























































































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { PromptTemplate, DefaultPromptTemplate } from "#/model/admin/index";
import RulesMixin from "#/mixins/rulesMixin";
import { VForm, Choice } from "@/types";
import OpenAISetting from "@/components/aivnplan/OpenAISetting.vue";
import GeminiSetting from "@/components/aivnplan/GeminiSetting.vue";
import { LLM_TYPE } from "@/views/aivnplan/const";

@Component({ components: { OpenAISetting, GeminiSetting } })
/**
 * プロンプトテンプレート一覧
 */
export default class AiMonthReportPromptTemplateEdit extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin
) {
  /** Ref */

  /** フォーム操作用 */
  @Ref("form") private readonly form!: VForm;

  /** 変数 */

  /** プロンプトテンプレート */
  private promptTemplate: PromptTemplate = DefaultPromptTemplate();

  /** 編集モードフラグ */
  private isEditMode = false;

  /** LLMサービス選択肢 */
  private llmServiceChoice: Choice[] = [
    {
      text: "OpenAI",
      value: LLM_TYPE.OPEN_AI,
    },
    {
      text: "Gemini",
      value: LLM_TYPE.GEMINI,
    },
  ];

  private LLM_TYPE = LLM_TYPE;

  /** お試し結果表示ダイアログ制御 */
  private isOpenTrialResultDialog = false;

  /** プロンプト(お試し結果) */
  private triedPrompt = "";

  /** 回答(お試し結果) */
  private triedAnswer = "";

  /** 関数 */

  created() {
    this.fetch();
  }

  /** 取得 */
  private fetch() {
    const promptTemplateId = Number(this.$route.params.id);
    this.postJsonCheck(
      window.base_url + "/api/admin/aimonthreport/prompt/get",
      { id: promptTemplateId },
      async (res) => {
        this.promptTemplate = res.data.prompt_template;
      }
    );
  }

  /** 保存 */
  private async save() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/admin/aimonthreport/prompt/save",
      { prompt_template: this.promptTemplate },
      async () => {
        this.$router.push({ path: "/ai-monthreport/prompt/list" });
      }
    );
  }

  /** キャンセル */
  private cancel() {
    this.$router.go(-1);
  }

  /** 編集モード切り替え */
  private toggleEditMode() {
    this.isEditMode = !this.isEditMode;
  }

  /** 削除 */
  private async deletePromptTemplate() {
    if (!(await this.$openConfirm("削除します。よろしいですか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/admin/aimonthreport/prompt/delete",
      { prompt_template: this.promptTemplate },
      async () => {
        this.$router.push({ path: "/ai-monthreport/prompt/list" });
      }
    );
  }

  /** プロンプトテンプレートを試す */
  private async trial() {
    if (
      !(await this.$openConfirm(
        "現在の入力内容でAIに問い合わせを行います。利用者データ挿入ブロックは手入力した内容が反映され、匿名化処理を適用した上でAIに送信されます。"
      ))
    ) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/admin/aimonthreport/prompt/trial",
      { prompt_template: this.promptTemplate },
      async (res) => {
        this.isOpenTrialResultDialog = true;
        this.triedPrompt = res.data.prompt;
        this.triedAnswer = res.data.answer;
      }
    );
  }
}
