var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('AppSubTitle',{staticClass:"ma-4"},[_vm._v("AI自動作成設定")]),_c('v-form',{ref:"form",staticClass:"ma-4 mb-8"},[_c('p',{staticClass:"mb-6"},[_vm._v(" 選択中のプロンプトテンプレートで月次報告書をAI自動作成します。 ")]),_c('div',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('AppSelect',{staticClass:"mr-4",attrs:{"label":"AI自動作成用プロンプトテンプレート","width":"360px","items":_vm.promptTemplates,"item-text":"name","item-value":"id","rules":[
            _vm.minNumber(
              _vm.setting.prompt_template_id,
              1,
              'プロンプトテンプレートを選択してください。'
            ) ]},model:{value:(_vm.setting.prompt_template_id),callback:function ($$v) {_vm.$set(_vm.setting, "prompt_template_id", $$v)},expression:"setting.prompt_template_id"}}),_c('AppAuthButton',{attrs:{"label":"保存","color":"primary"},on:{"click":_vm.save}})],1)],1)]),_c('AppSubTitle',{staticClass:"ma-4"},[_vm._v("プロンプトテンプレート一覧")]),_c('div',{staticClass:"ma-4"},[_c('AppDataTable',{staticClass:"elevation-0 adl-table",attrs:{"loadingText":"検索中...お待ちください","no-data-text":"データがありませんでした。","headers":_vm.headers,"items":_vm.promptTemplates,"pageNo":_vm.pageNo,"search":_vm.searchKeyword,"frontOnlyPageCount":_vm.itemPerPage,"isNoFilterButton":true,"loading":_vm.loading > 0},on:{"update:pageNo":_vm.changePage},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"align-center pt-2 mx-4",attrs:{"no-gutters":""}},[_c('AppTextField',{attrs:{"hide-details":"","clearable":"","width":"300px"},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('AppIcon',{attrs:{"icon":"search"}})]},proxy:true}]),model:{value:(_vm.searchKeyword),callback:function ($$v) {_vm.searchKeyword=$$v},expression:"searchKeyword"}}),_c('v-spacer'),_c('AppAuthButton',{attrs:{"label":"新規作成","color":"primary","className":"mr-4"},on:{"click":function($event){return _vm.moveEditPage(0)}}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('AppAuthButton',{attrs:{"label":"編集","color":"primary"},on:{"click":function($event){return _vm.moveEditPage(item.id)}}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }