import { InquiryDiv, InquiryGroup, InquiryServiceDiv } from ".";
import Base from "./../base";
import { FileIF } from "./file";

export interface InquiryAnswer {
  id: number; // ID
  inquiry_id: number; // 問い合わせID
  sort_no: number; // '並び順',
  answer_status: number; // '回答ステータス'
  is_published: number; // 公開フラグ\n0:非公開\n1:公開
  answer_admin_id: number; // '回答者ID',
  answer_admin_name: string; // '回答者名',
  answer_datetime: string; // 回答日時
  answer_div: number; // '回答区分',
  answer_content: string; // '回答内容',
  answer_time: number; // '回答時間',
  answer_datetime_wareki: string; // 回答日時(和暦),

  inquiry_answer_inquiry_divs: InquiryAnswerInquiryDiv[];
  inquiry_answer_inquiry_groups: InquiryAnswerInquiryGroup[];
  inquiry_answer_service_divs: InquiryAnswerServiceDiv[];
  inquiry_answer_files: InquiryAnswerFile[];
}
export interface InquiryAnswerInquiryDiv extends Base {
  id: number; //ID
  inquiry_answer_id: number; //'問い合わせ回答ID',
  inquiry_div_id: number; //'問い合わせ種類ID',
  sort_no?: number; //'並び順',
  name?: string; //'問い合わせ種類名',
}

/* 問い合わせ回答 - 問い合わせグループ */
export interface InquiryAnswerInquiryGroup extends Base {
  id: number; //ID
  inquiry_answer_id: number; //'問い合わせ回答ID',
  inquiry_group_id: number; //'問い合わせグループID',
  sort_no?: number; //'並び順',
  name?: string; //'問い合わせグループ名',
}

/* 問い合わせ回答 - 問い合わせサービス種類 */
export interface InquiryAnswerServiceDiv extends Base {
  id: number; //ID
  inquiry_answer_id: number; //'問い合わせ回答ID',
  service_div_id: number; //'サービス種類ID',
  sort_no?: number; //'並び順',
  name?: string; //'問い合わせサービス種類名',
}

/** 問い合わせ回答添付ファイル */
export interface InquiryAnswerFile extends Base, FileIF {
  /** ID */
  id: number;
  /** 問い合わせ回答ID */
  inquiry_answer_id: number;
  /** 並び順 */
  sort_no: number;
  /** ファイルパス */
  file_path: string;
}

export interface DispInquiryAnswer extends InquiryAnswer {
  can_release: boolean;

  selected_service_divs: InquiryServiceDiv[];
  selected_inquiry_groups: InquiryGroup[];
  selected_inquiry_divs: InquiryDiv[];
}

export const DEFAULT_INQUIRY_ANSWER: InquiryAnswer = {
  id: 0,
  inquiry_id: 0,
  sort_no: 1,
  answer_status: 1,
  is_published: 0,
  answer_admin_id: 0,
  answer_admin_name: "",
  answer_datetime: "",
  answer_div: 1,
  answer_content: "",
  answer_time: 0,
  answer_datetime_wareki: "",

  inquiry_answer_inquiry_divs: [],
  inquiry_answer_inquiry_groups: [],
  inquiry_answer_service_divs: [],
  inquiry_answer_files: [],
};

export const DEFAULT_INQUIRY_ANSWER_FILE: InquiryAnswerFile = {
  id: 0,
  inquiry_answer_id: 0,
  sort_no: 1,
  file_path: "",
  path: "",
  comment: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
};

export const DEFAULT_DISP_INQUIRY_ANSWER: DispInquiryAnswer = {
  ...DEFAULT_INQUIRY_ANSWER,
  can_release: false,
  selected_service_divs: [],
  selected_inquiry_groups: [],
  selected_inquiry_divs: [],
};
