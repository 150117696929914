





























































































































































import { Component, Mixins, Emit, Prop } from "vue-property-decorator";
import { Inquiry, InquiryAnswer } from "@/model/inquiry";
import {
  INQUIRY_STATUS,
  INQUIRY_STATUS_COLOR,
  HOPE_CONTACT_DIV,
  ANSWER_STATUS,
  ANSWER_COMPLETED,
  LISTED_ANSWER_STATUS,
  ANSWER_STATUS_COLOR,
  ANSWER_ASSIGN,
  ANSWER_COMPLETED_UNSOLVED,
  INQUIRY_VERSION,
  INQUIRY_VERSION_COLOR,
} from "@/consts/inquiry";
import UtilMixin from "@/mixins/utilMixin";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";

export interface HeaderColumn {
  text: string;
  value: string;
  sortable: boolean;
  align: string;
  width?: string;
  selected?: boolean;
  filterCondition?: number;
  filterType?: number;
  filter?: (val: object) => boolean;
}

/** 表示用Inquiry */
interface DispInquiry extends Inquiry {
  ticket_no: string;
  last_answered_datetime: string;
  co_code: string;
  kst_no: string;
  corresponding_admin_names: string;
  use_receipt_type: number;
  inquiry_date: string;
  inquiry_time: string;
  all_answer_contents: string;
  answer_statuses: number;
  need_mark_hope_contact_date_time: number;
  inquiry_staff_name: string;
  inquiry_staff_furigana: string;
  co_name: string;
  office_name: string;
}

@Component({
  components: {
    AppAuthButton,
  },
})
export default class InProgress extends Mixins(UtilMixin) {
  @Prop({ default: () => [] }) inquirys!: DispInquiry[];
  @Prop({ default: false }) disablePagination!: boolean;
  @Prop({ default: () => [] }) headers!: HeaderColumn[];
  @Prop({ default: () => [] }) sortBy!: [];
  @Prop({ default: () => [] }) sortDesc!: [];

  @Emit("update:sortBy") private updateSortBy(value: []) {
    return value;
  }

  @Emit("update:sortDesc") private updateSortDesc(value: []) {
    return value;
  }

  private get SortBy() {
    return this.sortBy;
  }
  private set SortBy(value) {
    this.updateSortBy(value);
  }

  private get SortDesc() {
    return this.sortDesc;
  }
  private set SortDesc(value) {
    this.updateSortDesc(value);
  }

  private INQUIRY_STATUS = INQUIRY_STATUS;
  private INQUIRY_STATUS_COLOR = INQUIRY_STATUS_COLOR;
  private INQUIRY_VERSION = INQUIRY_VERSION;
  private INQUIRY_VERSION_COLOR = INQUIRY_VERSION_COLOR;
  private ANSWER_STATUS = ANSWER_STATUS;
  private ANSWER_STATUS_COLOR = ANSWER_STATUS_COLOR;
  private HOPE_CONTACT_DIV = HOPE_CONTACT_DIV;
  private LISTED_ANSWER_STATUS = LISTED_ANSWER_STATUS;
  private ANSWER_COMPLETED = ANSWER_COMPLETED;
  private ANSWER_COMPLETED_UNSOLVED = ANSWER_COMPLETED_UNSOLVED;
  private ANSWER_ASSIGN = ANSWER_ASSIGN;

  /** 問い合わせ担当者を取得 */
  private getAdminNames(answers: InquiryAnswer[]): string {
    const adminNames: string[] = [];
    answers.forEach((answer: InquiryAnswer) => {
      if (!adminNames.includes(answer.answer_admin_name)) {
        adminNames.push(answer.answer_admin_name);
      }
    });

    return adminNames.length ? adminNames.join("/") : "";
  }

  // 詳細ボタンをクリックした場合は親コンポーネントに問い合わせIDを通知
  @Emit()
  show(inquiry: DispInquiry) {
    return inquiry.parent_inquiry_id > 0
      ? inquiry.parent_inquiry_id
      : inquiry.id;
  }
}
