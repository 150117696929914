import Base from "../base";

/** LLMパラメータ */
export interface LlmParameter {
  /** モデル名 */
  model: string;
}

export const DefaultLlmParameter = (): LlmParameter => ({
  model: "",
});

/** プロンプトテンプレート */
export interface PromptTemplate extends Base {
  /** ID */
  id: number;
  /** テンプレート名 */
  name: string;
  /** コメント */
  comment: string;
  /** データセットID */
  dataset_id: number;
  /** LLMサービス種類 1:OpenAI */
  llm_type: number;
  /** LLMサービス毎の設定情報(JSON) */
  llm_setting: string;
  /** 最終更新者ID */
  editor_admin_id: number;
  /** プロンプトテンプレートブロック情報 */
  blocks: PromptTemplateBlock[];
  /** LLMパラメータ */
  llm_parameter: LlmParameter;
}

export const DefaultPromptTemplate = (): PromptTemplate => ({
  id: 0,
  name: "",
  comment: "",
  dataset_id: 0,
  llm_type: 0,
  llm_setting: "",
  editor_admin_id: 0,
  blocks: [],
  created_at: "",
  updated_at: "",
  deleted_at: "",
  llm_parameter: DefaultLlmParameter(),
});

/** プロンプトテンプレートブロック */
export interface PromptTemplateBlock extends Base {
  /** ID */
  id: number;
  /** プロンプトテンプレートID */
  prompt_template_id: number;
  /** テキスト情報 */
  text: string;
  /** 編集可能ブロックフラグ */
  is_editable_block: number;
  /** データセット挿入ブロックフラグ */
  is_dataset_block: number;
}
