/** PortalOffice公開フラグ */
export const PORTAL_OFFICE_IS_PUBLIC = [
  { text: "公開", value: true },
  { text: "非公開", value: false },
];

export const PORTAL_OFFICE_IS_PUBLIC_COLOR = [
  { text: "blue", value: true },
  { text: "grey", value: false },
];

/** PortalOffice審査ステータス */
export const PORTAL_OFFICE_APPROVAL_STATUS = [
  { text: "未処理", value: 0 },
  { text: "処理中", value: 1 },
  { text: "承認済み", value: 2 },
  { text: "差し戻し", value: 3 },
  { text: "却下", value: 4 },
];

export const PORTAL_OFFICE_APPROVAL_STATUS_COLOR = [
  { text: "red", value: 0 },
  { text: "pink", value: 1 },
  { text: "blue", value: 2 },
  { text: "amber", value: 3 },
  { text: "brown", value: 4 },
];

/** PortalOffice掲載情報初回審査ステータス */
export const PORTAL_OFFICE_LISTING_REVIEW_STATUS = [
  { text: "準備中", value: 0 },
  { text: "掲載承認待ち", value: 1 },
  { text: "審査完了", value: 2 },
  { text: "差し戻し", value: 3 },
];

export const PORTAL_OFFICE_LISTING_REVIEW_STATUS_COLOR = [
  { text: "grey", value: 0 },
  { text: "pink", value: 1 },
  { text: "blue", value: 2 },
  { text: "brown", value: 3 },
];

/** PortalOfficeお知らせ配信先区分 */
export const PORTAL_OFFICIAL_ANNOUNCEMENT_DESTINATION = [
  { text: "紹介側", value: 1 },
  { text: "掲載側", value: 2 },
];

/** PortalOfficeお知らせステータス */
export const PORTAL_OFFICIAL_ANNOUNCEMENT_STATUS = [
  { text: "下書き", value: 1 },
  { text: "配信予約済み", value: 2 },
  { text: "配信済み", value: 3 },
];

export const PORTAL_OFFICIAL_ANNOUNCEMENT_STATUS_COLOR = [
  { text: "blue-grey-lighten-4", value: 1 },
  { text: "pink", value: 2 },
  { text: "light-blue", value: 3 },
];

/** PortalStaff権限 */
export const PORTAL_STAFF_ROLE = [
  { text: "管理", value: 1 },
  { text: "一般", value: 2 },
];
