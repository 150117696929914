






























import { Component, Mixins } from "vue-property-decorator";
import {
  InquiryContact,
  DispInquiry,
  DispInquiryAnswer,
  DEFAULT_DISP_INQUIRY,
  OfficestaffContact,
  DEFAULT_OFFICESTAFF_CONTACT,
} from "@/model/inquiry";
import InquiryInfo from "@/components/inquiry/InquiryInfo.vue";
import AnswerInfo from "@/components/inquiry/AnswerInfo.vue";
import InquiryContactInfo from "@/components/inquiry/InquiryContactInfo.vue";
import InquiryDetail from "@/components/inquiry/InquiryDetail.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UserMixin from "@/mixins/userMixin";
import { Choice } from "@/types";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import OfficestaffContactInfo from "@/components/inquiry/OfficestaffContactInfo.vue";

@Component({
  components: {
    InquiryInfo,
    InquiryContactInfo,
    AnswerInfo,
    AppAuthButton,
    OfficestaffContactInfo,
    InquiryDetail,
  },
})
export default class InquiryShow extends Mixins(AxiosMixin, UserMixin) {
  /** 対象問い合わせ情報（親） */
  private inquiry: DispInquiry = { ...DEFAULT_DISP_INQUIRY };

  /** 対象問い合わせ情報（親子含む） */
  private inquiries: DispInquiry[] = [];

  /** 事業所職員コンタクト履歴情報 */
  private officeStaffContact: OfficestaffContact = {
    ...DEFAULT_OFFICESTAFF_CONTACT,
  };

  /** 問い合わせタグマスタ情報 */
  private inquiryTagChoices: Choice[] = [];

  created() {
    this.fetchInquiry();
  }

  /** ID指定で問い合わせ情報を取得 */
  private fetchInquiry(): void {
    const inquiryId = Number(this.$route.params.id);

    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/get",
      { id: inquiryId },
      (res) => {
        this.inquiry = res.data.inquiry;
        this.officeStaffContact = res.data.inquiry.officestaff_contact;
        this.inquiryTagChoices = res.data.inquiry_tag_choices;
        this.inquiries = res.data.inquiries;
      }
    );
  }

  /** 問い合わせ編集ボタンクリック時 */
  private toggleEditMode(inquiryId: number) {
    //ページ遷移
    this.$router.push({
      path: `/inquiry/${this.inquiry.id}/edit/${inquiryId}`,
    });
  }

  /** 問い合わせステータス更新 */
  private saveStatus(obj: { inquiry: DispInquiry; inquiry_status: number }) {
    this.postJsonCheck(
      window.base_url + "/api/admin/inquirys/status/update",
      {
        inquiry: obj.inquiry,
        inquiry_status: obj.inquiry_status,
        change_type: 1,
      },
      () => {
        this.fetchInquiry();
      }
    );
  }

  /** 問い合わせタグ更新 */
  private saveTag(obj: { inquiry: DispInquiry; inquiry_tag_id: number }) {
    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/tag/update",
      {
        inquiry: obj.inquiry,
        inquiry_tag_id: obj.inquiry_tag_id,
        change_type: 2,
      },
      () => {
        this.fetchInquiry();
      }
    );
  }

  /** 回答起票ボタンクリック時 */
  private async clickNewAnswer(inquiryId: number) {
    //ページ遷移
    if (
      !(await this.$openConfirm("新規に回答を起票します\nよろしいですか？"))
    ) {
      return;
    }
    this.$router.push({
      path: `/inquiry/${this.inquiry.id}/answer/edit/${inquiryId}/0`,
    });
  }

  /** 回答編集ボタンクリック時 */
  private clickAnswer(obj: { inquiry_id: number; answer_id: number }) {
    //ページ遷移
    this.$router.push({
      path: `/inquiry/${this.inquiry.id}/answer/edit/${obj.inquiry_id}/${obj.answer_id}`,
    });
  }

  /** コンタクト履歴保存時 */
  private saveInquiryContact(inquiryContact: InquiryContact) {
    // コンタクト履歴は必ず初回問い合わせに紐づけて保存
    inquiryContact.inquiry_id = this.inquiry.id;

    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/contact/save",
      {
        inquiry_contact: inquiryContact,
        agreement_id: this.inquiry.agreement_id,
      },
      () => {
        this.fetchInquiry();
      }
    );
  }

  /** 問い合わせの公開設定を変更した場合 */
  private async onChangeInquirySwitch(inquiry: DispInquiry) {
    if (inquiry.is_published == 1) {
      if (!inquiry.can_release) {
        await this.$openAlert(
          "内容に不備があるため、問い合わせを公開できません。\n編集画面で内容を修正してください。"
        );
        this.$nextTick(() => {
          inquiry.is_published = 0;
        });
        return;
      }

      if (
        !(await this.$openConfirm("問い合わせを公開します。よろしいですか？"))
      ) {
        this.$nextTick(() => {
          inquiry.is_published = 0;
        });
        return;
      }
    }
    if (inquiry.is_published == 0) {
      if (
        !(await this.$openConfirm(
          "問い合わせを非公開にします。よろしいですか？"
        ))
      ) {
        this.$nextTick(() => {
          inquiry.is_published = 1;
        });
        return;
      }
    }
    this.saveInquiry(inquiry);
  }

  /** 回答の公開設定を変更した場合 */
  private async onChangeInquiryAnswerSwitch(obj: {
    inquiry: DispInquiry;
    inquiry_answer: DispInquiryAnswer;
  }) {
    if (obj.inquiry_answer.is_published == 1) {
      if (!obj.inquiry_answer.can_release) {
        await this.$openAlert(
          "内容に不備があるため、回答を公開できません。\n編集画面で内容を修正してください。"
        );
        this.$nextTick(() => {
          obj.inquiry_answer.is_published = 0;
        });
        return;
      }

      if (!(await this.$openConfirm("回答を公開します。よろしいですか？"))) {
        this.$nextTick(() => {
          obj.inquiry_answer.is_published = 0;
        });
        return;
      }
    }
    if (obj.inquiry_answer.is_published == 0) {
      if (
        !(await this.$openConfirm("回答を非公開にします。よろしいですか？"))
      ) {
        this.$nextTick(() => {
          obj.inquiry_answer.is_published = 1;
        });
        return;
      }
    }
    this.saveInquiryAnswer(obj.inquiry, obj.inquiry_answer);
  }

  /** 問い合わせ保存 */
  private saveInquiry(inquiry: DispInquiry) {
    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/publish/update",
      {
        inquiry: inquiry,
      },
      () => {
        this.fetchInquiry();
      }
    );
  }

  /** 回答保存 */
  private saveInquiryAnswer(
    inquiry: DispInquiry,
    inquiry_answer: DispInquiryAnswer
  ) {
    this.postJsonCheck(
      window.base_url + "/api/admin/inquiry/answer/publish/update",
      {
        inquiry: inquiry,
        inquiry_answer: inquiry_answer,
      },
      () => {
        this.fetchInquiry();
      }
    );
  }

  /** 事業所職員コンタクト履歴保存 */
  private saveOfficeStaffContact(contact: OfficestaffContactInfo) {
    this.postJsonCheck(
      window.base_url + "/api/admin/officestaff/contact/save",
      { officestaff_contact: contact },
      () => {
        this.fetchInquiry();
      }
    );
  }

  /** 職員が所属する契約への管理者ログイン */
  private systemLogin(agreementId: number) {
    this.postJsonCheck(
      window.base_url + "/api/admin/ibow/admin-login",
      {
        agreement_id: agreementId,
      },
      (res) => {
        window.open(
          window.ibow_url + "?token=" + res.data.onetime_token,
          "_blank"
        );
      }
    );
  }

  /** 職員で代理ログイン */
  private agentLogin(staffId: number) {
    this.postJsonCheck(
      window.base_url + "/api/admin/ibow/agent-login",
      {
        staff_id: staffId,
      },
      (res) => {
        window.open(
          window.ibow_url + "?token=" + res.data.onetime_token,
          "_blank"
        );
      }
    );
  }
}
