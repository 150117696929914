import Base from "../base";

/** 月次報告書AI自動作成設定 */
export interface AiMonthReportSetting extends Base {
  /** ID */
  id: number;
  /** プロンプトテンプレートID */
  prompt_template_id: number;
  /** 最終更新者ID */
  editor_admin_id: number;
}

export const DefaultAiMonthReportSetting = (): AiMonthReportSetting => ({
  id: 0,
  prompt_template_id: 0,
  editor_admin_id: 0,
  created_at: "",
  updated_at: "",
  deleted_at: "",
});
