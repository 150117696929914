


























































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import ChangeContractDialog from "@/components/contractmanagement/ChangeContractDialog.vue";
import OfficeStaffChangeDialog from "@/components/contractmanagement/OfficeStaffChangeDialog.vue";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import OfficeInfo from "@/components/contractreception/OfficeInfo.vue";
import AppAuthButton from "@/components/admin_common/AppAuthButton.vue";
import OutputExcelDialog from "@/components/contractmanagement/OutputExcelDialog.vue";
import InputExcelDialog from "@/components/contractmanagement/InputExcelDialog.vue";
import TrumbowygEditor from "@/components/trumbowyg/TrumbowygEditor.vue";
import TextDayChangePicker from "#/components/TextDayChangePicker.vue";
import RulesMixin from "#/mixins/rulesMixin";
import { VForm } from "@/types";

import {
  PORTAL_OFFICIAL_ANNOUNCEMENT_DESTINATION,
  PORTAL_OFFICIAL_ANNOUNCEMENT_STATUS,
} from "@/consts/portal";

import {
  PortalOfficialAnnouncement,
  DEFAULT_OFFICIAL_ANNOUNCEMENT,
} from "@/model/portal";

@Component({
  components: {
    ChangeContractDialog,
    OfficeInfo,
    AppAuthButton,
    OfficeStaffChangeDialog,
    OutputExcelDialog,
    InputExcelDialog,
    TrumbowygEditor,
    TextDayChangePicker,
  },
})
export default class PortalOfficialAccouncementEdit extends Mixins(
  AxiosMixin,
  UtilMixin,
  RulesMixin
) {
  @Ref("inputForm") private readonly inputForm!: VForm;
  /** 初回ロード完了フラグ */
  private initLoadingDone = false;
  private announcement: PortalOfficialAnnouncement = {
    ...DEFAULT_OFFICIAL_ANNOUNCEMENT,
  };
  private showSaveCompleteBar = false;

  private PORTAL_OFFICIAL_ANNOUNCEMENT_DESTINATION =
    PORTAL_OFFICIAL_ANNOUNCEMENT_DESTINATION;
  private PORTAL_OFFICIAL_ANNOUNCEMENT_STATUS =
    PORTAL_OFFICIAL_ANNOUNCEMENT_STATUS;

  public async created() {
    await this.fetchItem(Number(this.$route.params.id));
    this.initLoadingDone = true;
  }

  /** 運営からのお知らせ */
  private async fetchItem(id: number) {
    if (id == 0) {
      return;
    }
    return new Promise((resolve) => {
      this.postJsonCheck(
        window.base_url + "/api/admin/portal_official_announcement/get",
        { official_announcement_id: id },
        (res) => {
          this.announcement = res.data.official_announcement;
          resolve(0);
        }
      );
    });
  }

  /** 保存ボタンクリック時 */
  private async save(item: PortalOfficialAnnouncement) {
    if (item.announced_at == " :") {
      item.announced_at = "";
    }
    if (!this.inputForm.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    if (!(await this.$openConfirm("保存しますか？"))) {
      return;
    }
    // 配信完了以外
    if (item.status != 3) {
      item.status = 2; // 配信予約済み
    }

    this.postJsonCheck(
      window.base_url + "/api/admin/portal_official_announcement/save",
      { official_announcement: item },
      (res) => {
        this.announcement = res.data.official_announcement;
        this.showSaveCompleteBar = true;
        this.$router.push({ path: `/portal-announcement/list` });
      }
    );
  }

  // キャンセルボタンクリック
  private async cancel() {
    if (
      await this.$openConfirm(
        "編集を終了します\n編集中の場合は内容が破棄されます"
      )
    ) {
      this.$router.push({ path: `/portal-announcement/list` });
    }
  }
}
