



























































































import { Component, Mixins, Prop } from "vue-property-decorator";
import {
  InquiryAnswer,
  InquiryAnswerServiceDiv,
  InquiryAnswerInquiryDiv,
  InquiryAnswerInquiryGroup,
  DEFAULT_INQUIRY_ANSWER,
} from "@/model/inquiry";
import {
  ANSWER_STATUS,
  ANSWER_STATUS_COLOR,
  ANSWER_METHOD,
  INQUIRY_PUBLISHED,
  INQUIRY_PUBLISHED_COLOR,
  ANSWER_ASSIGN,
} from "@/consts/inquiry";
import UtilMixin from "@/mixins/utilMixin";
import AnswerContent from "@/components/inquiry/answerContent.vue";
import AttachmentViewer from "@/components/admin_common/AttachmentViewer.vue";

@Component({
  components: {
    AnswerContent,
    AttachmentViewer,
  },
})
export default class AnswerInfo extends Mixins(UtilMixin) {
  @Prop({ default: DEFAULT_INQUIRY_ANSWER }) inquiryAnswer!: InquiryAnswer;

  private ANSWER_STATUS = ANSWER_STATUS;
  private ANSWER_STATUS_COLOR = ANSWER_STATUS_COLOR;
  private ANSWER_METHOD = ANSWER_METHOD;

  /** 回答ステータス：アサイン */
  private ANSWER_ASSIGN = ANSWER_ASSIGN;

  /** 公開設定選択肢 */
  private INQUIRY_PUBLISHED = INQUIRY_PUBLISHED;

  /** 公開設定色分け */
  private INQUIRY_PUBLISHED_COLOR = INQUIRY_PUBLISHED_COLOR;

  /** サービス種類 */
  private get serviceDivs() {
    const serviceDivsStrs: string[] =
      this.inquiryAnswer.inquiry_answer_service_divs.map(
        (serviceDiv: InquiryAnswerServiceDiv) => {
          return serviceDiv.name ? serviceDiv.name : "";
        }
      );
    return serviceDivsStrs.join(" / ");
  }

  /** 区分 */
  private get divs() {
    const divsStrs: string[] =
      this.inquiryAnswer.inquiry_answer_inquiry_divs.map(
        (div: InquiryAnswerInquiryDiv) => {
          return div.name ? div.name : "";
        }
      );
    return divsStrs.join(" / ");
  }

  /** グループ */
  private get groups() {
    const groupsStrs: string[] =
      this.inquiryAnswer.inquiry_answer_inquiry_groups.map(
        (group: InquiryAnswerInquiryGroup) => {
          return group.name ? group.name : "";
        }
      );
    return groupsStrs.join(" / ");
  }
}
