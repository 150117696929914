var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" けあログっと 運営からのお知らせ編集 "),_c('v-spacer')],1),_c('v-card-text',[_c('v-form',{ref:"inputForm"},[_c('v-subheader',{staticClass:"pl-0"},[_vm._v("お知らせ対象 "),(_vm.announcement.status == 3)?_c('div',[_c('v-spacer'),_c('v-spacer'),_vm._v(" ※"+_vm._s(_vm.announcement.announced_at)+"にメール/SMS送信済みのため変更できません ")],1):_vm._e()]),_c('AppSelect',{staticClass:"mt-2 mb-2",attrs:{"label":"配信先","hide-details":"","items":_vm.PORTAL_OFFICIAL_ANNOUNCEMENT_DESTINATION,"width":"100%","rules":[
            _vm.required(_vm.announcement.destination, '配信先を選択して下さい。') ],"disabled":_vm.announcement.status == 3},model:{value:(_vm.announcement.destination),callback:function ($$v) {_vm.$set(_vm.announcement, "destination", $$v)},expression:"announcement.destination"}}),_c('v-subheader',{staticClass:"pl-0"},[_vm._v("メール/SMSを配信する "),(_vm.announcement.status == 3)?_c('div',[_c('v-spacer'),_c('v-spacer'),_vm._v(" ※"+_vm._s(_vm.announcement.announced_at)+"にメール/SMSを送信済みのため変更できません ")],1):_vm._e()]),_c('AppCheckbox',{staticClass:"mr-1 my-2",attrs:{"label":"メール/SMSを配信する","true-value":true,"false-value":false,"hide-details":"","disabled":_vm.announcement.status == 3},model:{value:(_vm.announcement.is_mail_on),callback:function ($$v) {_vm.$set(_vm.announcement, "is_mail_on", $$v)},expression:"announcement.is_mail_on"}}),(_vm.announcement.status == 3)?_c('div',[_vm._v(" ※掲載開始日時がメール配信日となります。掲載開始日時を過去に戻してもメールが再配信されることはありません。 ")]):_c('div',[_vm._v("※掲載開始日時がメール配信日となります。")]),_c('v-subheader',{staticClass:"pl-0"},[_vm._v("アプリ内通知およびメール/SMS送信開始日時")]),_c('AppDatetimePicker',{attrs:{"label":"掲載開始日時","rules":[
            _vm.required(
              _vm.announcement.display_start_at,
              '掲載開始日時を入力してください。'
            ) ]},model:{value:(_vm.announcement.display_start_at),callback:function ($$v) {_vm.$set(_vm.announcement, "display_start_at", $$v)},expression:"announcement.display_start_at"}}),_vm._v(" 〜 "),_c('AppDatetimePicker',{attrs:{"label":"通知掲載終了日時","rules":[
            _vm.required(
              _vm.announcement.display_end_at,
              '掲載終了日時を入力してください。'
            ) ]},model:{value:(_vm.announcement.display_end_at),callback:function ($$v) {_vm.$set(_vm.announcement, "display_end_at", $$v)},expression:"announcement.display_end_at"}}),_c('v-subheader',{staticClass:"pl-0"},[_vm._v("件名")]),_c('AppTextField',{staticClass:"mt-2 mb-2",attrs:{"label":"件名","hide-details":"","width":"100%","rules":[_vm.required(_vm.announcement.title, '件名を入力してください。')]},model:{value:(_vm.announcement.title),callback:function ($$v) {_vm.$set(_vm.announcement, "title", $$v)},expression:"announcement.title"}}),_c('v-subheader',{staticClass:"pl-0"},[_vm._v("本文")]),_c('AppTextArea',{staticClass:"mt-2 mb-2",attrs:{"label":"本文","isNeed":true,"showCounter":true,"simpleCounter":true,"limitPerLine":2000,"maxLines":1,"rules":[_vm.required(_vm.announcement.body, '本文を入力してください。')]},model:{value:(_vm.announcement.body),callback:function ($$v) {_vm.$set(_vm.announcement, "body", $$v)},expression:"announcement.body"}})],1)],1),_c('v-card-actions',[_c('AppAuthButton',{attrs:{"color":"primary","label":"保存","className":"mr-2"},on:{"click":function($event){return _vm.save(_vm.announcement)}}}),_c('v-btn',{on:{"click":function($event){return _vm.cancel()}}},[_vm._v("キャンセル")])],1)],1),_c('v-snackbar',{attrs:{"color":"primary","timeout":2000},model:{value:(_vm.showSaveCompleteBar),callback:function ($$v) {_vm.showSaveCompleteBar=$$v},expression:"showSaveCompleteBar"}},[_vm._v(" "+_vm._s("保存しました。")+" ")]),_c('AppLoadingDialog',{attrs:{"loading":_vm.loading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }